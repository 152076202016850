<template>
  <div>
    <v-container>
      <!--Mostrar el precio de entrada sin el iva-->
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td>{{ textIniPrice }}</td>

              <td>
                <v-layout justify-end>
                  {{ `${currency} ${monedaFormatter.format(priceSIva)}` }}
                </v-layout>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-divider style="margin-bottom: 2em;"></v-divider>

      <!-- División entre regla de precio y descuentos. por medio de pantallas.. -->
      <!--Seleccionar una regla de precio -->
      <v-select
        :items="priceRulesAvailable"
        :label="seletedPriceRules ? word(61) : word(59)"
        v-model="seletedPriceRules"
        @change="purchaseScheme"
        return-object
        item-text="description"
      ></v-select>

      <v-data-table
        v-if="seletedPriceRules"
        :headers="headersPurchaseScheme"
        :items="itemsPurchaseSchemeJoin"
        :hide-default-footer="true"
      >
        <template v-slot:no-data>
          Tienes que escoger una regla de precio para continuar
        </template>
      </v-data-table>

      <!-- Descuentos Extras -->
      <v-simple-table v-if="seletedPriceRules">
        <template v-slot:default>
          <tbody>
            <tr v-for="(item, i) in purchaseSchemeData.extraDiscounts" :key="i">
              <td>
                {{ item.text }}
              </td>
              <td>
                <v-layout justify-end>
                  <div class="text-end">
                    <v-btn
                      outlined
                      class="mx-2 my-1 mt-0"
                      dark
                      small
                      color="primary"
                      @click="removeDiscounts(item)"
                    >
                      {{ word(57) }}

                      <v-icon dark color="primary" size="20">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </div>

                  {{ item.percent }}
                </v-layout>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <div
        class="text-center"
        v-if="discountsAllowed > 0 && filterDiscounts.length > 0"
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on" block outlined>
              {{ `${word(56)} ${seletedPriceRules.number_apply_discount})` }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in filterDiscounts"
              :key="index"
              @click="addExtraDiscount(item)"
            >
              <v-list-item-title>{{
                item.description_method_discount
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <!-- Total descuento  y precio final -->
      <v-simple-table v-if="seletedPriceRules">
        <template v-slot:default>
          <tbody>
            <tr v-if="fixedDiscount() != 0">
              <td>{{ `${word(47)}` }}</td>

              <td>
                <v-layout justify-end>
                  {{ `${currency} ${monedaFormatter.format(fixedDiscount())}` }}
                </v-layout>
              </td>
            </tr>

            <tr>
              <td>{{ textFinalPrice }}</td>

              <td>
                <v-layout justify-end>
                  {{ `${currency} ${monedaFormatter.format(finalPriceSIva)}` }}
                </v-layout>
              </td>
            </tr>

            <tr v-if="!workWithoutVAT">
              <td>{{ `${word(33)} (${iva}%)` }}</td>

              <td>
                <v-layout justify-end>
                  {{ `${currency} ${monedaFormatter.format(finalPrice)}` }}
                </v-layout>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
  </div>
</template>
<script>
import { word } from "@/pdf/advanced-quoter/multi-language.js";
export default {
  name: "AdvancedPriceRule",

  props: {
    properties: {
      Array: Array,
      default: null
    },
    development: {
      Object: Object,
      default: null
    },
    priceRules: {
      Array: Array,
      default: null
    },
    discounts: {
      Array: Array,
      default: null
    },
    workWithoutVAT: {
      Boolean: Boolean,
      default: false
    },
    language: {
      String: String,
      default: "Spanish"
    }
  },

  watch: {
    properties() {
      this.resetData();
      this.init();
    },
    joinData() {
      this.packedData();
    },
    language() {
      if (this.seletedPriceRules) {
        this.purchaseSchemeLanguage(this.seletedPriceRules);
      }
    }
  },
  data() {
    return {
      //Precio inicial : es la suma de todas la propiedades. Es el precio de entrada..
      startPrice: 0,
      //El precio final : es el precio final para hacer la cotización..
      endPrice: 0,
      //Regla de precio seleccionada..
      seletedPriceRules: null,
      //Reglas de precios disponibles despues de validar..
      priceRulesAvailable: null,
      //Precio total
      totalPrice: 0,
      currency: "",
      //Primera tabla con los porcentajes..
      headersPurchaseScheme: [
        { text: "", value: "text", sortable: false },
        { text: "", value: "percent", sortable: false, align: "right" }
      ],
      itemsPurchaseScheme: [],

      //Segunda tabla con las reglas de precios...
      monedaFormatter: new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0
      }),
      //IVA
      iva: 0,
      addIva: false,
      selectedDiscounts: [],
      purchaseSchemeData: {
        priceRules: [],
        priceRulesDiscount: [],
        extraDiscounts: [],
        totalDiscount: 0,
        numberApplyDiscount: 0,
        discounts: []
      }
    };
  },
  computed: {
    textIniPrice() {
      if (this.workWithoutVAT) return this.word(41);
      return this.word(31);
    },
    textFinalPrice() {
      if (this.workWithoutVAT) return this.word(42);
      return this.word(32);
    },
    filterDiscounts() {
      //Hay que sacar los descuentos que ya estan aplicados en la regla de precio..
      //Los que incluye la regla de precio y los descuentos extras..
      const dtIds = [
        ...this.purchaseSchemeData.extraDiscounts,
        ...this.purchaseSchemeData.priceRulesDiscount
      ];
      let discountFilter = this.purchaseSchemeData.discounts;
      dtIds.forEach(dt => {
        if (dt._id) {
          discountFilter = discountFilter.filter(ft => ft._id !== dt._id);
        }
      });
      return discountFilter;
    },
    joinData() {
      return [
        ...this.purchaseSchemeData.priceRules,
        ...this.purchaseSchemeData.priceRulesDiscount,
        ...this.purchaseSchemeData.extraDiscounts
      ];
    },
    itemsPurchaseSchemeJoin() {
      return [
        ...this.purchaseSchemeData.priceRules,
        ...this.purchaseSchemeData.priceRulesDiscount
      ];
    },
    allDiscounts() {
      return [
        ...this.purchaseSchemeData.priceRulesDiscount,
        ...this.purchaseSchemeData.extraDiscounts
      ];
    },
    itemsTableComputed() {
      let data = [];
      this.itemsTable.forEach(e => {
        e.percent = e.percent + "%";
        data.push(e);
      });
      return data;
    },
    //Precio con IVA y sin IVA
    finalPrice() {
      if (this.workWithoutVAT) return this.finalPriceSIva;
      return this.minusPercentValue(this.finalPriceSIva, this.iva);
    },
    finalPriceSIva() {
      return this.priceSIva - this.purchaseSchemeData.totalDiscount;
    },

    //Precios iniciales..
    priceIva() {
      if (this.workWithoutVAT) return this.startPrice;
      if (!this.addIva) {
        return this.startPrice * (this.iva / 100 + 1);
      } else {
        return this.startPrice;
      }
    },
    priceSIva() {
      if (this.workWithoutVAT) return this.startPrice;
      if (this.addIva) {
        return this.subtractIVA(this.startPrice, this.iva);
      } else {
        return this.startPrice;
      }
    },
    discountsAllowed() {
      return this.surplusDiscounts();
    }
  },
  created() {
    this.init();
  },
  methods: {
    word(n) {
      if (this.language == "spanish") return word(n, "spanish");
      else return word(n, "english");
    },
    //Eliminar el iva del precio..
    subtractIVA(price, iva) {
      if (!price) return 0;
      if (!iva) return 0;

      const tiva = iva / 100 + 1;
      return price / tiva;
    },
    init() {
      //Calcular Precio con IVA y sin IVA..
      //Obtener el iva.. si no existe usar datos por default
      this.getIva(this.development);
      //Mostrar el iva temporal
      this.priceRulesAvailable = this.evaluatePriceRulesAvailable().filter(
        item => item.status == 1
      );
      [this.startPrice, this.currency] = this.addition();
      //Filtrar los descuentos globales..
      this.purchaseSchemeData.discounts = this.conditionalDiscounts(
        this.properties.length,
        this.discounts
      );
    },
    evaluatePriceRulesAvailable() {
      if (this.properties.length > 1) return this.priceRules;
      if (this.properties.length == 1) return this.priceRulesTogether();
      return [];
    },
    priceRulesTogether() {
      const rules = this.properties[0].pricing_rules;
      return rules.concat(this.priceRules);
    },
    addition() {
      let amount = 0;
      let currency = "";
      this.properties.forEach(p => {
        amount +=
          p.pricing && p.pricing.price
            ? this.transformNumber(p.pricing.price)
            : 0;
        currency = p.pricing && p.pricing.currency ? p.pricing.currency : "";
      });
      return [amount, currency];
    },
    //Validar que sea un numero..
    transformNumber(number) {
      if (typeof number == "number") return number;
      if (number == "") return 0;
      return parseFloat(number);
    },
    //Obtener el iva
    getIva(dev) {
      if (!dev.miscellaneous) {
        this.defaultIva();
        return 0;
      }
      if (!dev.miscellaneous.quote) {
        this.defaultIva();
        return 0;
      }
      const quote = dev.miscellaneous.quote;
      this.addIva = quote.include_vat ? quote.include_vat : false;
      this.iva = quote.vat > 0 ? quote.vat : 0;
    },
    defaultIva() {
      this.addIva = true;
      this.iva = 0;
    },
    percentValue(value, percent) {
      return (value * percent) / 100;
    },
    minusPercentValue(value, percent) {
      return value + this.percentValue(value, percent);
    },
    //Aplicar reglas de precio inciales el esquema de compra..
    purchaseScheme(item) {
      //Vaciar todo
      this.purchaseSchemeData.priceRules = [];
      this.purchaseSchemeData.priceRulesDiscount = [];
      this.purchaseSchemeData.extraDiscounts = [];
      //Reglas de precio con porcentajes..
      this.addPriceRules(item);
      //Descuentos en reglas de precios..
      this.addPriceRulesDiscounts(item);
    },
    purchaseSchemeLanguage(item) {
      //Reglas de precio con porcentajes..
      this.addPriceRules(item);
      //Descuentos en reglas de precios..
      this.addPriceRulesDiscounts(item);
    },

    applyDiscount(variable, discounts) {
      discounts.forEach(dis => {
        variable.push({
          text: dis.description_method_discount,
          percent: `${dis.discount}%`
        });
      });
    },
    calculateDiscount(discount, method, price) {
      if (method === "percentage") {
        return price * this.calculatePercent(discount, 0);
      }
      return discount;
    },
    percenTwoNumbers(a, b) {
      return ((a / b) * 100).toFixed(2);
    },
    percentDifference(valueNew, valueOld) {
      return ((valueNew - valueOld) / valueOld) * 100;
    },

    addPriceRules(item) {
      let temData = [];
      //Apartado..
      if (item.guarantee_deposit) {
        temData.push({
          text: this.word(44),
          percent: `${this.currency} ${this.monedaFormatter.format(
            item.guarantee_deposit
          )}`
        });
      }

      //Enganche..
      if (item.percentage_down_payment) {
        temData.push({
          text: this.word(18),
          percent: `${item.percentage_down_payment}%`,
          percentValue: item.percentage_down_payment
        });
      }
      //Financiamiento..
      if (item.financing) {
        temData.push({
          text: this.word(19),
          percent: `${item.financing}%`,
          percentValue: item.financing
        });
      }
      //Liquidación
      if (item.termination_payment) {
        temData.push({
          text: this.word(34),
          percent: `${item.termination_payment}%`,
          percentValue: item.termination_payment
        });
      }
      this.purchaseSchemeData.priceRules = temData;
    },
    //Descuentos que incluye la regla de precio..
    addPriceRulesDiscounts(item) {
      this.selectedDiscounts = [];
      this.purchaseSchemeData.numberApplyDiscount = item.number_apply_discount
        ? item.number_apply_discount
        : 0;
      if (!item.discounts) return 0;
      if (item.discounts.length < 1) return 0;

      let tempData = [];
      //Se aplica el condicional para los decuentos dentro de la regla de precio..
      const discounts = this.conditionalDiscounts(
        this.properties.length,
        item.discounts
      );

      this.selectedDiscounts = discounts;
      discounts.forEach(dis => {
        tempData.push({
          _id: dis._id,
          text: dis.description_method_discount,
          percent: this.getTypeValue(dis.method_discount, dis.discount),
          value: dis.discount,
          type: dis.method_discount
        });
      });
      this.purchaseSchemeData.priceRulesDiscount = tempData;
    },
    getTypeValue(type, value) {
      if (type === "percentage") {
        return `${value}%`;
      } else {
        return `${this.currency} ${this.monedaFormatter.format(value)}`;
      }
    },
    fixedDiscount() {
      let amount = 0;

      if (this.allDiscounts) {
        amount += this.calculateDiscountPro(this.priceSIva, this.allDiscounts);
      }
      return amount;
    },
    calculateDiscountPro(price, items) {
      let percent = 0; //Cantidad que se suma y al final se saca..
      let amount = 0; //Se va sumando;
      items.forEach(e => {
        percent += e.type === "percentage" ? e.value : 0;
        amount += e.type === "fixed" ? e.value : 0;
      });
      this.purchaseSchemeData.totalDiscount =
        this.percentValue(price, percent) + amount;
      return this.purchaseSchemeData.totalDiscount;
    },
    //Validación para los descuentos..
    conditionalDiscounts(count, discounts) {
      const withoutConditional = discounts.filter(d => d.condition == "");
      const greaterConditional = this.greater(count, discounts);
      const equalsConditional = this.equals(count, discounts);
      const lessConditional = this.less(count, discounts);

      return [
        ...withoutConditional,
        ...greaterConditional,
        ...equalsConditional,
        ...lessConditional
      ];
    },
    greater(count, discounts) {
      ///Propiedades Mayor que -> cantidad de propiedades mayores a condicional
      const check = discounts.filter(d => d.condition == "greater");
      return check.filter(d => count > d.properties_apply_discount);
    },
    equals(count, discounts) {
      //Propiedades Igual que -> La Condicional
      const check = discounts.filter(d => d.condition == "equals");
      return check.filter(d => count == d.properties_apply_discount);
    },
    less(count, discounts) {
      //Propiedades Menor que --> La condicional
      const check = discounts.filter(d => d.condition == "less");
      return check.filter(d => count < d.properties_apply_discount);
    },
    addExtraDiscount(dis) {
      //Agregarlo al array y eliminarlo de la lista..
      this.purchaseSchemeData.extraDiscounts.push({
        _id: dis._id,
        text: dis.description_method_discount,
        percent: this.getTypeValue(dis.method_discount, dis.discount),
        value: dis.discount,
        type: dis.method_discount
      });
    },
    //Contar los descuentos disponible y que estan sobrantes...
    surplusDiscounts() {
      //Descuentos sobrantes para usar en unidad computada....
      if (
        this.seletedPriceRules &&
        this.seletedPriceRules.number_apply_discount
      ) {
        const accumulatedDiscounts = this.allDiscounts.length;
        const countDiscount = this.seletedPriceRules.number_apply_discount;
        const result = countDiscount - accumulatedDiscounts;
        return result;
      } else {
        return 0;
      }
    },
    removeDiscounts(item) {
      this.purchaseSchemeData.extraDiscounts = this.purchaseSchemeData.extraDiscounts.filter(
        exd => exd._id != item._id
      );
    },
    resetData() {
      //Al cambiar las propiedades seleccionadas..
      this.startPrice = 0;
      this.endPrice = 0;
      this.seletedPriceRules = null;
      this.priceRulesAvailable = null;
      this.totalPrice = 0;
      this.currency = "";
      this.iva = 0;
      this.addIva = false;
      this.selectedDiscounts = [];
      this.itemsPurchaseScheme = [];

      this.purchaseSchemeData.priceRules = [];
      this.purchaseSchemeData.priceRulesDiscount = [];
      this.purchaseSchemeData.extraDiscounts = [];
      this.purchaseSchemeData.totalDiscount = 0;
      this.purchaseSchemeData.numberApplyDiscount = 0;
      this.purchaseSchemeData.discounts = [];
    },
    //Construccion del paquete de datos que se envia al componente padre para hacer el pdf..
    packedData() {
      //Tienes que aplicar el descuento, de lo contrario no hace el calculo..
      this.fixedDiscount();
      const objecScheme = {
        //Regla de precio aplicada..
        priceRuleApplied: this.purchaseSchemeData,
        //Regla de precio...
        seletedPriceRules: this.seletedPriceRules,
        //Precio inicial con iva..
        initPrice: this.priceIva,
        //Precio inicial sin iva..
        initPriceSIva: this.priceSIva,
        //Precio final con iva..
        finalPrice: this.finalPrice,
        //Precio final sin iva..
        finalPriceSIva: this.finalPriceSIva,
        //Moneda..
        currency: this.currency,
        //iva..
        iva: this.workWithoutVAT ? 0 : this.iva,
        //Descuento total...
        totalDiscounts: this.fixedDiscount()
      };
      this.$emit("setPackedPriceRule", objecScheme);
    }
  }
};
</script>
